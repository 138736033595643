.Input_Container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  gap: 20px;
  margin: auto;
  transition: 0.5s;
}
.Input_Container .fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.Input_Container .fieldset .label {
  color: #35a967;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-left: 0px;
}
.Input_Container .fieldset .input {
  border: 3px solid grey;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  width: 400px;
  color: #35a967;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
}
@media only screen and (max-width: 1000px) {
  .Input_Container .fieldset .input {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .Input_Container {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .Input_Container {
    width: 100%;
  }
}/*# sourceMappingURL=Inputs.module.css.map */