.Main {
    background-color: rgb(0, 0, 0, .9);

    display: flex;
    flex-direction: column;

    position: relative;


    margin: auto;

    height: 100vh;
    width: 100%;

    transition: .5s;

    .Container {
        display: flex;
        
        padding: 10px;
        gap: 50px;
        justify-content: center;

        margin: auto;

        
        .Input_Container {
            display: flex;
            flex-direction: column;

            position: relative;

            gap: 20px;

            margin: auto;
            

            transition: .5s;

            .fieldset {
                border: none;

                display: flex;
                flex-direction: column;

                gap: 10px;

                width: 100%;

                .label {
                    color: #35a967;
                    font-family: sans-serif;
                    font-size: 20px;
                    font-weight: 400;

                    margin-left: 0px;
                }

                .input {
                    border: 3px solid grey;
                    background-color: rgb(0, 0, 0, .9);
                    padding: 10px;

                    width: 400px;

                    color: #35a967;
                    font-family: sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                }
                
                    @media only screen and (max-width: 1000px) {
                        .input {
                            width: 100%;
                        }
                    }
            }

        }


                
        .Display {
            border: 5px solid grey;
            background-color: rgb(0, 0, 0, .9);

            display: grid;
            grid-template-columns: 1fr;

            position: relative;

            padding: 5px;
            gap: 0px;

            margin: auto;

            height: 500px;
            width: 600px;

            transition: .5s;

            overflow: scroll;
            overflow-x: hidden;

            .Header {
                display: flex;

                margin: auto;
                margin-top: 0px;

                height: 60px;
                width: 100%;
        
                    .Input_Counter {
                        border: 3px solid grey; 
        
                        display: flex;
        
                        position: absolute;
                        top: 10px;
                        left: 10px;
        
                        height: 50px;
                        width: 120px;
        
                        .Count {
                            color: #35a967;
                            font-family: sans-serif;
                            font-size: 20px;
                            font-weight: 400;
        
                            margin: auto;
                        }
                    }
        
                    .Date_Box {
                        border: 3px solid grey; 
        
                        display: flex;
        
                        position: absolute;
                        top: 10px;
                        right: 10px;
        
                        height: 50px;
                        width: 120px;
                        
                        .Date {
                            color: #35a967;
                            font-family: sans-serif;
                            font-size: 20px;
                            font-weight: 400;
        
                            margin: auto;
                        }
                    }
            }


            .Task_List {
                display: flex;
                flex-direction: column;

                padding: 5px;
                

                margin-top: 0px;

                width: 100%;

                .Task {
                    display: flex;
            
                    .Task_Icon {
                        margin: auto;
                        margin-left: 0px;
                        height: 30px;
                        width: 30px;
                    }
                    
                    .The_Task {
                        color: #35a967;
                        font-family: sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        
                        margin: auto;

                        display: flex;
                        flex-wrap: wrap;
                    }
            
                    .Delete_Icon {
                        margin: auto;
                        margin-right: 0px;
                        height: 30px;
                        width: 30px;
                    }

                }
            }
        }

        .Display::-webkit-scrollbar {
            display: none;
        }
} 



    
    @media only screen and (max-width: 1000px) {
        .Container {
            gap: 20px;
            flex-direction: column;

            width: 80%;

            .Input_Container {
                width: 90%;
            }

            .Display {
                width: 90%;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        .Container {
            width: 100%;

            .Input_Container {
                width: 100%;
            }

            .Display {
                width: 100%;

                .The_Task {
                    font-size: 15px;
                }
            }

        }
    }
}



    
