

.Footer {
    border: 3px solid #35a967;
    background-color: rgb(0, 0, 0, .9);

    display: flex;

    position: relative;

    padding: 10px;

    margin: auto;
    margin-bottom: 0px;

    height: 80px;
    width: 500px;

    transition: .5s;
}

@media only screen and (max-width: 768px) {
    .Footer {
        width: 100%;
    }
}